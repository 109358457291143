.toast-warning > .toast-message {
    color: black;
}

.page-item {
    cursor: pointer;
}

.disabled {
    cursor: not-allowed;
    color: black;
}

.table {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}
.pagination {
    overflow-x: auto;
    white-space: nowrap;
    margin-bottom: 0px;
}

@font-face {
    font-family: 'Consolas';
    src: url('../public/fonts/Consolas.ttf') format('truetype');
}

.rdw-editor-main {
    border: 1px solid #ced4da;
    border-radius: .25rem;;
}